<template>
  <div id="p_slider">
    <div id="p_slider_content">
      <div id="p_menus">
        <a
          v-for="(item, index) in this.$props.items"
          :key="`silder_${index}`"
          @click="onClick(index)"
          :id="this.selectedIndex===index? 'selected_a' : 'normal_a'"
        >
          {{ item.name }}
        </a>
      </div>
      <hr />
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    items: Array,
  },
  data() {
    return {
      selectedIndex: 0,
      defaultColor: "#00000080",
      selectedColor: "#f1c889",
    };
  },
  methods: {
    onClick(index) {
      this.selectedIndex = index;
      this.selectedColor = "#f1c889";

      this.$emit("switchTab", index);
    },
  },
})
export default class CustomSlider extends Vue {}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#p_slider {
  width: 100px;
  left: 40px;
}

#p_slider_content {
  display: flex;
  flex-direction: row;
  padding-right: 10px;
}

#p_menus {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100px;
  padding-right: 5px;
  height: 50px;
}

hr {
  margin: 5px 0 0px 0;
  border: 0;
  width: 1px;
  margin-right: 20px;
  border-left: 1px solid #99999940;
}

#normal_a {
  font-size: 12px;
  color: #666;
  vertical-align: middle;
  text-align: end;
  padding-right: 10px;
}

#selected_a {
  font-size: 12px;
  color: #337a00;
  vertical-align: middle;
  text-align: end;
  padding-right: 10px;
}
</style>
