<template>
  <div id="p_aloe_produce_slider">
    <CustomSlider :items="items" v-on:switchTab="handleSwitchTab" />
    <div id="p_aloe_produce_list">
      <div id="p_aloe_produce_item" v-for="(item, index) in list" :key="index">
        <box-with-style-2 :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/PC/CustomSlider.vue";
import BoxWithStyle2 from "@/components/PC/BoxWithStyle2.vue";
import { kAloePageContentInfo } from "@/config/aloe";

@Options({
  components: {
    CustomSlider,
    BoxWithStyle2,
  },
  data() {
    return {
      info: kAloePageContentInfo,
      selectedIndex: 0,
      list: kAloePageContentInfo.skinCare.items,
      items: [
        {
          name: "芦荟护肤",
        },
        {
          name: "芦荟洗护",
        },
      ],
    };
  },
  methods: {
    handleSwitchTab(index) {
      this.selectedIndex = index;
      this.list =
        index === 0
          ? kAloePageContentInfo.skinCare.items
          : kAloePageContentInfo.wash.items;
    },
  },
})
export default class AloeProduceList extends Vue {}
</script>

<style scoped>
@import url("../../../../less/pc/Aloe/index.less");
</style>