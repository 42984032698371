
export const kAloePageContentInfo = {
    header: {
        title: "护 肤 本 质",
        des: "遵从护肤本质，遵从芦荟本质。",
        items: [
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/intro1.png",
                des: "“万非”护肤品牌，主张倡导“内在美、自然美、中国美”",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/intro2.png",
                des: "反对崇洋媚外、过度包装、明星天价代言营销",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/intro3.png",
                des: "追求更加真实的、内在的、天然的品质",
            },
        ],
    },
    produce: {
        title: "产 品 专 区",
        desList: [
            "护肤化妆品的主要应用到的芦荟原料分为两种：库拉索芦荟冻干粉、库拉索芦荟凝胶汁。",
            "芦荟是低调而神奇的护肤品，具有天然保湿、修复细胞、促进伤口愈合、抗氧化、抗紫外线等重要功效",
        ],
    },
    skinCare: {
        title: "芦荟护肤", 
        items: [
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/10.png",
                title: "鲜萃芦荟水",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/9.png",
                title: "芦荟胶",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/7.png",
                title: "万非活肤乳",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/8.png",
                title: "精华原液"
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/4.png",
                title: "修护面霜",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/3.png",
                title: "芦荟防护乳",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/1.png",
                title: "芦荟花睡眠面膜",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/5.png",
                title: "芦荟润唇膏",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe1/6.png",
                title: "万非精粹面膜",
            },
        ],
    },
    wash: {
        title: "芦荟洗护",
        items: [
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/3.png",
                title: "芦荟氨基酸洁面乳",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/6.png",
                title: "芦荟洁面泡",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/5.png",
                title: "芦荟玫瑰洗发露",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/7.png",
                title: "芦荟牙膏",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/1.png",
                title: "芦荟美容皂",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/2.png",
                title: "芦荟玫瑰沐浴露",
            },
            {
                img: "https://guinong-dev-1305192904.cos.ap-shanghai.myqcloud.com/wanfei-offical/aloe2/4.png",
                title: "芦荟氨基酸沐浴露",
            },
        ],
    },
}
