<template>
  <div id="p_aloe_produce_header">
    <div id="p_aloe_produce_header_title">{{ produceInfo.title }}</div>
    <div id="p_aloe_produce_header_des_content">
      <img id="p_aloe_produce_header_icon" src="@/assets/doubleQuotes.png" />
      <div
        v-for="(des, index) in produceInfo.desList"
        :key="index"
        id="p_aloe_produce_header_text"
      >
        {{ des }}
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import { kAloePageContentInfo } from "@/config/aloe";

@Options({
  data() {
    return {
      produceInfo: kAloePageContentInfo.produce,
    };
  },
})
export default class Al extends Vue {}
</script>

<style scoped>
@import url("../../../../less/pc/Aloe/index.less");
</style>