<template>
  <div class="pc_page_container_all">
    <aloe-header />
    <aloe-produce-header />
    <aloe-produce-list />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import AloeHeader from "./SubDiv/Header.vue";
import AloeProduceHeader from "./SubDiv/ProduceHeader.vue";
import AloeProduceList from "./SubDiv/ProduceList.vue";

@Options({
  components: {
    AloeHeader,
    AloeProduceHeader,
    AloeProduceList,
  },
})
export default class AloePage extends Vue {}
</script>

<style scoped>
@import url("../../../less/pc/page.less");
</style>