<template>
  <div id="box_style2_content">
    <img id="box_style2_img" :src="this.$props.item.img" />
    <div id="box_style2_text_content">
      <div id="box_style2_text">{{ this.$props.item.title }}</div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  components: {},
  props: {
    item: Object,
  },
})
export default class BoxWithStyle2 extends Vue {}
</script>

<style scoped>
@import url("../../less/pc/Boxs/index.less");
</style>