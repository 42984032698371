<template>
  <div id="p_aloe_header">
    <div id="p_aloe_header_title">{{ headerInfo.title }}</div>
    <div id="p_aloe_header_des">{{ headerInfo.des }}</div>
    <div id="p_aloe_header_list">
      <div id="p_aloe_header_item" v-for="(item, index) in headerInfo.items" :key="index">
        <box-with-style-1 :item="item" />
      </div>
    </div>
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import BoxWithStyle1 from "@/components/PC/BoxWithStyle1.vue";
import { kAloePageContentInfo } from "@/config/aloe";

@Options({
  components: {
    BoxWithStyle1,
  },
  data() {
    return {
      headerInfo: kAloePageContentInfo.header,
    };
  },
})
export default class AloeHeader extends Vue {}
</script>

<style scoped>
@import url("../../../../less/pc/Aloe/index.less");
</style>